import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'

import {AppComponent} from './app.component'
import {QrModule} from '@jhc/qr'

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    QrModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
